import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Button, TextField, Typography, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ChangePhoneModal = ({
  isOpen,
  onRequestClose,
  phone,
  setPhone,
  error,
  isSubmitting,
  handleSubmit,
  classes,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    style={{
      overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 200 },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        padding: 0,
      },
    }}
  >
    <div className={classes.modalContent}>
      <IconButton className={classes.closeButton} onClick={onRequestClose}>
        <FontAwesomeIcon icon={faTimes} style={{ width: "20px", height: "20px" }} />
      </IconButton>
      <Typography className={classes.title}>
        เปลี่ยนเบอร์โทรศัพท์
      </Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        label="เบอร์โทร"
        fullWidth
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        error={!!error}
        helperText={error}
      />
      <Button
        className={classes.continueButton}
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? "กำลังดำเนินการ..." : "ดำเนินการต่อ"}
      </Button>
    </div>
  </Modal>
);

ChangePhoneModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  error: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ChangePhoneModal;