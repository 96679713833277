import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Grid, TextField } from "@material-ui/core";
import Layout from "../../components/Layout";
import ChangePhoneModal from "../../components/Modal/ChangePhone";
import OtpModal from "../../components/Modal/OtpModal";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import auth from "../../utils/auth";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import "./styles/profile.css"
import { InputAdornment } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birth_date: "",
      first_name: "",
      last_name: "",
      errors: {
        first_name: "",
        last_name: "",
        birth_date: "",
      },
      isChangePhoneModalOpen: false, // ควบคุมการเปิด/ปิดโมดาล
      phone: "", // สำหรับเก็บเบอร์โทรศัพท์ใหม่
      phoneError: "", // สำหรับข้อความแจ้งข้อผิดพลาด
      isSubmitting: false, // สำหรับสถานะการส่งข้อมูล
      isOtpModalOpen: false, // ควบคุมการเปิด/ปิด OtpModal
      otp: "", // สำหรับเก็บรหัส OTP
      otpError: "", // สำหรับแสดงข้อผิดพลาด OTP
      countdown: 60, // เวลานับถอยหลังสำหรับการขอ OTP ใหม่
      isSubmittingOtp: false, // สถานะการส่ง OTP
      requestToken: null,
      ref: "",
    };
    this.timer = null; // ตัวจับเวลาสำหรับ countdown
  }

  componentDidMount = async () => {
    const user = auth.getUserInfo();
    if (!user){
      window.location.href = '/';
    }
    if (user.birth_date) {
      // แปลง birth_date ให้เป็น Date object หรือ moment object
      user.birth_date = moment(user.birth_date).toDate();
    }
    this.setState(user);
  };

  handleBirthDateChange = (date) => {
    this.setState({ birth_date: new Date(date) });
    this.setState({ errors: {} });
  };

  validateFields = () => {
    const { birth_date } = this.state;
    console.log('birth_date -->', birth_date);
    let errors = {};
    let isValid = true;
    // if (birth_date && !/^\d{4}-\d{2}-\d{2}$/.test(birth_date)) {
    //   errors.birth_date = "รูปแบบวันเกิดไม่ถูกต้อง (YYYY-MM-DD)";
    //   isValid = false;
    // }
    if (moment(birth_date).isValid()) {
    }else{
      if(birth_date){
        errors.birth_date = "รูปแบบวันเกิดไม่ถูกต้อง (YYYY-MM-DD)";
        isValid = false;
      }
    }
    this.setState({ errors });
    return isValid;
  };

  handleOpenChangePhoneModal = () => {
    this.setState({ isOtpModalOpen: false, otp: "", otpError: "" });
    this.setState({ isChangePhoneModalOpen: true });
  };
  
  handleCloseChangePhoneModal = () => {
    this.setState({ isChangePhoneModalOpen: false, phoneError: "" });
  };

  handleChangePhoneSubmit = async () => {
    const { phone } = this.state;
  
    if (!/^\d{10}$/.test(phone)) {
      this.setState({ phoneError: "เบอร์โทรศัพท์ต้องเป็นตัวเลข 10 หลัก" });
      return;
    }
  
    this.setState({ isSubmitting: true, phoneError: "" });
  
    try {

      const response = await auth.sendOTP(phone);
      this.setState({ countdown: 60, ref: response.data.ref, requestToken: response.data.request_token });
      this.handleCloseChangePhoneModal();
      this.handleOpenOtpModal();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถเปลี่ยนเบอร์โทรศัพท์ได้",
        confirmButtonText: "ตกลง",
      });
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  handleSubmit = async () => {
    if (this.validateFields()) {
      try {
        const { first_name, last_name, birth_date } = this.state;
        const response = await this.props.customer.update({ first_name, last_name, birth_date })
        auth.setUserInfo(response)
        Swal.fire({
          icon: "success",
          title: "สำเร็จ!",
          text: "บันทึกข้อมูลสำเร็จ!",
          confirmButtonText: "ตกลง",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล",
          confirmButtonText: "ตกลง",
        });
      }
    }
  };

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: "",
      },
    }));
  };

  handleOpenOtpModal = () => {
    this.setState({ isOtpModalOpen: true, otpError: "", countdown: 60 }, () => {
      this.startCountdown();
    });
  };
  
  startCountdown = () => {
    if (this.timer) clearInterval(this.timer); // รีเซ็ตตัวจับเวลาถ้ามีการเรียกซ้ำ
    this.timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.countdown <= 1) {
          clearInterval(this.timer); // หยุด timer เมื่อ countdown ถึง 0
          return { countdown: 0 };
        }
        return { countdown: prevState.countdown - 1 };
      });
    }, 1000); // ลดค่า countdown ทุก 1 วินาที
  };
  
  handleCloseOtpModal = () => {
    clearInterval(this.timer); // หยุด timer
    this.setState({ isOtpModalOpen: false, otp: "", otpError: "" });
  };
  
  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer); // หยุด timer เมื่อ component ถูกทำลาย
  }

  handleVerifyOtp = async () => {
    const { otp, phone, ref, requestToken } = this.state;
  
    if (otp.length !== 6) {
      this.setState({ otpError: "กรุณากรอก OTP ให้ครบ 6 หลัก" });
      return;
    }
  
    this.setState({ isSubmittingOtp: true, otpError: "" });
  
    try {
      // เรียกใช้ API ตรวจสอบ OTP
      const response = await auth.verifyOTPChangePhone(phone, otp, ref, requestToken);
      auth.setToken(response.data.token);
      auth.setUserInfo(response.data.info);
      Swal.fire({
        icon: "success",
        title: "สำเร็จ!",
        text: "เปลี่ยนเบอร์โทรศัพท์สำเร็จ!",
        confirmButtonText: "ตกลง",
      }).then(() => {
        // รีโหลดหน้าเว็บหลังจากกด "ตกลง"
        window.location.reload();
      });
      this.handleCloseOtpModal();
    } catch (error) {
      this.setState({ otpError: error.message });
    } finally {
      this.setState({ isSubmittingOtp: false });
    }
  };
  
  renderBirthDateInput = (props) => {
    const { errors } = this.state;
    return (
      <div>
        <TextField
          variant="outlined"
          id={'startDate'}
          {...props}
          error={!!errors.birth_date}
          helperText={errors.birth_date}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarToday style={{ cursor: "pointer" }} />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }

  handleResendOtp = async () => {
    const { phone } = this.state;
    this.setState({ isSubmittingOtp: true });
    try {
      // const response = await http.post('/customers/login', { email_or_phone: emailOrPhone });
      const response = await auth.sendOTP(phone);
      this.setState({ countdown: 60, ref: response.data.ref, requestToken: response.data.request_token });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถส่งรหัส OTP ได้",
        confirmButtonText: "ตกลง",
      });
    } finally {
      this.setState({ isSubmittingOtp: false });
    }
  };

  // handleResendOtp = async () => {
  //   this.setState({ isSubmittingOtp: true });
  
  //   try {
  //     // เรียกใช้ API ขอ OTP ใหม่
  //     const response = await auth.sendOTP(emailOrPhone);
  //     setRef(response.data.ref);  
  //     this.setState({ countdown: 60 });
  //     Swal.fire({
  //       icon: "success",
  //       title: "ส่งรหัส OTP ใหม่แล้ว",
  //       text: "กรุณาตรวจสอบข้อความของคุณ",
  //       confirmButtonText: "ตกลง",
  //     });
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "เกิดข้อผิดพลาด",
  //       text: "ไม่สามารถส่งรหัส OTP ได้",
  //       confirmButtonText: "ตกลง",
  //     });
  //   } finally {
  //     this.setState({ isSubmittingOtp: false });
  //   }
  // };

  render() {
    const { classes } = this.props;
    const { first_name, last_name, errors, birth_date } = this.state;

    return (
      <Layout page="profile">
        <div className={classes.bookingContainer}>
          <div className={classes.spaceBox}></div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Paper>
                <div className={classes.bookingForm}>
                  <h3>
                    <FontAwesomeIcon icon={faAddressCard} /> แก้ไขข้อมูล
                  </h3>
                  <Button
                    size="large"
                    style={{ marginTop: "30px" }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={this.handleOpenChangePhoneModal}
                  >
                    เปลี่ยนเบอร์โทรศัพท์
                  </Button>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>ชื่อ</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={first_name}
                          onChange={(e) =>
                            this.handleChange("first_name", e.target.value)
                          }
                          error={!!errors.first_name}
                          helperText={errors.first_name}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>สกุล</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={last_name}
                          onChange={(e) =>
                            this.handleChange("last_name", e.target.value)
                          }
                          error={!!errors.last_name}
                          helperText={errors.last_name}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>วันเกิด</h4>
                        <Datetime
                          timeFormat={false}
                          value={birth_date || ''}
                          onChange={this.handleBirthDateChange}
                          renderInput={this.renderBirthDateInput} />
                      </div>
                    </Grid>

                  </Grid>
                  <Button
                    size="large"
                    style={{ marginTop: "50px" }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={this.handleSubmit}
                  >
                    บันทึก
                  </Button>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
          <ChangePhoneModal
            isOpen={this.state.isChangePhoneModalOpen}
            onRequestClose={this.handleCloseChangePhoneModal}
            phone={this.state.phone}
            setPhone={(value) => this.setState({ phone: value, phoneError: "" })}
            error={this.state.phoneError}
            isSubmitting={this.state.isSubmitting}
            handleSubmit={this.handleChangePhoneSubmit}
            classes={classes}
          />
          <OtpModal
            isOpen={this.state.isOtpModalOpen}
            onRequestClose={this.handleCloseOtpModal}
            emailOrPhone={this.state.phone}
            otp={this.state.otp}
            setOtp={(value) => this.setState({ otp: value, otpError: "" })}
            error={this.state.otpError}
            isSubmitting={this.state.isSubmittingOtp}
            handleVerifyOtp={this.handleVerifyOtp}
            countdown={this.state.countdown}
            handleResendOtp={this.handleResendOtp}
            onChangeContact={this.handleOpenChangePhoneModal} // กลับไปเปลี่ยนเบอร์
            classes={classes}
          />
        </div>
      </Layout>
    );
  }
}

const styles = () => ({
  boxForm: {
    marginTop: "24px",
    fontFamily: `Kanit`,
    "& h4": {
      marginBottom: "10px",
      fontFamily: `Kanit`,
    },
    "& input": {
      fontFamily: `Kanit`,
    },
  },
  bookingContainer: {
    "& span": {
      fontFamily: `Kanit`,
    },
  },
  bookingForm: {
    padding: "16px",
    "& h3": {
      marginTop: "30px",
      fontFamily: `Kanit`,
      color: "#444444",
    },
    "& h6": {
      marginLeft: "10px",
      fontFamily: `Kanit`,
      color: "#3f51b5",
    },
  },
  spaceBox: {
    height: "30px",
  },
  media: {
    fontFamily: `Kanit`,
    width: "100%",
    height: "200px",
  },
  titleText: {
    fontFamily: `Kanit`,
    display: "flex",
    justifyContent: "space-between",
    color: "#444444",
  },
  subTitleText: {
    fontFamily: `Kanit`,
    display: "flex",
    justifyContent: "space-between",
  },
  lightText: {
    fontFamily: `Kanit`,
    fontSize: "13px",
  },
  appBar: {
    backgroundColor: "#1a1a1a",
    color: "#fff",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  modalContent: {
    padding: "30px",
    borderRadius: "8px",
    textAlign: "center",
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#f5f5f5",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    position: "relative", // for positioning the close button
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: "#888",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    marginBottom: "10px",
  },
  continueButton: {
    width: "100%",
    backgroundColor: "#e2c366",
    color: "#1a1a1a",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    marginTop: "10px",
  },
  socialButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    marginBottom: "10px",
    border: "1px solid #ddd",
    textTransform: "none",
    color: "#555",
    position: "relative",
  },
  icon: {
    position: "absolute",
    left: "10px",
  },
  facebookButton: {
    color: "#3b5998",
  },
  googleButton: {
    color: "#db4437",
  },
  termsText: {
    fontSize: "12px",
    color: "#888",
    marginTop: "20px",
  },
  resendText: {
    marginTop: "10px",
    fontSize: "14px",
    color: "#888",
  },
  otpDescription: {
    marginBottom: "20px",
    fontSize: "14px",
  },
  changeContact: {
    fontSize: "14px",
    color: "#888",
    marginTop: "15px",
    textAlign: "center",
  },
  otpValidity: {
    fontSize: "14px",
    color: "#888",
    marginTop: "10px",
    marginBottom: "20px",
    textAlign: "center",
  },
  popper: {
    zIndex: 1500,
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    minWidth: "200px",
    padding: "10px 0",
  },
  menuItem: {
    padding: "12px 16px",
    fontSize: "14px",
    color: "#333",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#f0f0f0", // สีพื้นหลังเมื่อ hover
      color: "#e2c366", // สีตัวอักษรเมื่อ hover
    },
    "&:not(:last-child)": {
      borderBottom: "1px solid #ddd", // เส้นแบ่งระหว่างรายการ
    },
  },
  logoutItem: {
    padding: "12px 16px",
    fontSize: "14px",
    color: "#e2c366",
    fontWeight: 500,
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f9f9f9", // สีพื้นหลังเมื่อ hover
    },
  },
});

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const page = inject("customer")(observer(Profile));
export default withStyles(styles, { withTheme: true })(page);